import React, { useContext } from "react";
import { Link } from "gatsby";
import Case from "case";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import CartContext from "../../context/cart-context";
import { formatPrice } from "../../utilities/price";

function CartItemDetail({ product }) {
  const cartContext = useContext(CartContext);
  const price = product.salePrice ? product.salePrice : product.basePrice;

  function removeItemFromCart() {
    cartContext.removeItemFromCart(product.id);
  }

  return (
    <div
      key={product.id}
      className="flex flex-wrap justify-between my-4 py-2 items-top"
    >
      <div className="w-1/3 md:w-1/5">
        <Link to={product.url}>
          <img
            src={product.image}
            alt={product.title}
            style={{ maxWidth: "110px" }}
          />
        </Link>
      </div>
      <div className="w-2/3 pl-4 md:w-4/5">
        <div className="flex flex-wrap pr-6 items-center">
          <div className="w-full md:w-2/3 flex-grow">
            <h5 className="text-base font-medium mb-2 pt-1">
              <Link className="no-underline text-grey-darkest" to={product.url}>
                {product.title}
              </Link>
            </h5>
            {product.size && (
              <div className="text-grey text-sm">Size: {product.size}</div>
            )}
            {product.extraCost !== 0 && (
              <div className="text-grey text-sm">
                + {formatPrice(product.extraCost)}
              </div>
            )}
          </div>
          <div className="w-1/2 md:w-1/6">
            <Formik
              initialValues={{ quantity: product.quantity }}
              onSubmit={(values, { setSubmitting }) => {
                cartContext.setItemQuantity(product.id, values.quantity);
                setSubmitting(false);
              }}
              validationSchema={Yup.object().shape({
                quantity: Yup.number().required("Required"),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <input
                      type="number"
                      name="quantity"
                      value={values.quantity}
                      onChange={(e) => {
                        handleChange(e);
                        setTimeout(() => {
                          handleSubmit();
                        });
                      }}
                      onBlur={handleBlur}
                      min="1"
                      className={
                        errors.quantity && touched.quantity
                          ? "py-2 px-4 w-full border border-red-dark"
                          : "py-2 px-4 w-full border border-grey-dark"
                      }
                    />
                    {errors.quantity && touched.quantity && (
                      <div className="text-red-dark">{errors.quantity}</div>
                    )}
                  </form>
                );
              }}
            </Formik>
          </div>
          <div className="w-1/2 md:w-1/6 text-xs font-light px-4">
            <div className="text-sm pl-2">{formatPrice(price)}</div>
            {!!product.discount && (
              <div className="text-sm pl-2">
                Discount: {formatPrice(product.discount)}
              </div>
            )}
          </div>
        </div>
        <p className="pt-4 text-sm">
          <button className="underline" onClick={removeItemFromCart}>
            Delete
          </button>
        </p>
      </div>
    </div>
  );
}

CartItemDetail.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  quantity: PropTypes.number,
  attributes: PropTypes.array,
};

export default CartItemDetail;
