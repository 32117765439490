import React, { useContext } from "react";
import { Link } from "gatsby";
import pluralize from "pluralize";
import Layout from "../components/layout";
import Seo from "../components/seo";
import CartContext from "../context/cart-context";
import CartItemDetail from "../components/partials/cart-item-detail";
import RecommendedProductsCta from "../components/partials/recommended-products-cta";
import { calculateProductTotal } from "../utilities/price";

function CartPage() {
  const {
    contents,
    recommendedProducts: getRecommendedProducts,
    totals,
  } = useContext(CartContext);

  const recommendedProducts = getRecommendedProducts()
    ? getRecommendedProducts()
    : [];

  return (
    <Layout>
      <Seo
        title="Cart"
        description={`Shopping with ${process.env.COMPANY_NAME} is easy. Simply add products to your cart and checkout`}
      />
      <div className="container mx-auto mb-8">
        <div className="bg-white w-full lg:w-auto pt-0 md:p-0">
          <div
            className={`${
              totals.countItems && recommendedProducts.length
                ? "flex flex-wrap"
                : "hidden"
            } mt-8`}
          >
            <div className="pb-4 w-full text-white">
              <h3 className="mb-4 px-4 md:p-0 text-xl font-semibold text-grey-darkest uppercase">
                We also recommend
              </h3>
              <RecommendedProductsCta />
            </div>
          </div>
          <div className="p-4 md:p-0 pb-3 pr-2 uppercase flex justify-between h-16 items-end border-b border-grey-light">
            <h3 className="text-xl font-semibold text-grey-darkest">
              Shopping Cart
            </h3>
            {totals.countItems > 0 && (
              <p>
                {totals.countItems} {pluralize("Item", totals.countItems)}
              </p>
            )}
          </div>
          <div className="flex flex-wrap mt-8">
            <div className="p-4 md:p-0 w-full md:w-2/3">
              {contents.map((product) => {
                return <CartItemDetail key={product.id} product={product} />;
              })}
              <div
                className={`overflow-y-scroll p-8 ${
                  contents.length ? "hidden" : ""
                }`}
              >
                <h3>Your cart is empty</h3>
                {contents.length === 0 && (
                  <p className="mt-4">
                    <Link to="/" className="text-primary">
                      Continue shopping
                    </Link>
                  </p>
                )}
              </div>
            </div>
            {contents.length > 0 && (
              <div className="py-4 w-full md:w-1/3">
                <div className="flex justify-end py-4 px-4 md:px-0 uppercase">
                  <Link
                    to="/checkout/"
                    className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded`}
                  >
                    Checkout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CartPage;
